import { Card } from "antd";
import React from "react";
import { styled } from "linaria/react";
import { theme } from "@project/shared/src/theme";
import Link from "next/link";
import { useTranslation } from "react-i18next";

interface LinkCardProps {
  logo?: string;
  title?: string;
  description?: string;
  link?: string;
  loading?: boolean;
}
const MainContainer = styled.div`
  & > div {
    height: 100%;
  }
  & .ant-card-body {
    padding: 17px 20px 29px 30px;
    display: flex;
    height: 100%;
    flex-direction: column;
    & > div {
      margin-top: auto;
    }
  }

  @media (max-width: 1015px) {
    & .ant-card-body {
      padding: 16px 16px 24px 16px;
    }
  }
`;

const StyledCard = styled(Card)`
  border: none;
  background-color: ${theme.white};
  padding: 0px 0px;
  border-radius: 10px;
  min-width: 315px;
  max-width: 383px;

  & img {
    display: block;
    max-width: 265px;
    height: 80px;
    width: 100%;
    object-fit: contain;
    margin-bottom: 24px;
    @media (max-width: 330px) {
      max-width: 255px;
      height: 75px;
    }
  }
  & strong {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: ${theme.textColor};
    word-wrap: break-word;
  }
  & p {
    margin: 16px 0px;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: ${theme.textColor};
    opacity: 0.6;
    word-wrap: break-word;
  }
  & .link {
    color: ${theme.buttonRed};
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
  }
  @media (min-width: 1441px) {
    max-width: 100%;
  }
  @media (max-width: 1060px) {
    min-width: auto;
    max-width: 100%;
    & img {
      margin-bottom: 16px;
    }
  }
  @media (max-width: 600px) {
    margin: 0 auto;
  }
`;

const LinkCard = ({
  logo,
  title,
  description,
  link,
  loading,
}: LinkCardProps) => {
  const { t } = useTranslation();
  return (
    <MainContainer>
      <StyledCard loading={loading}>
        {logo && <img alt={title} src={logo} width={252} height={51} />}
        <strong>{title}</strong>
        <p>{description}</p>
        <div>
          <Link href={link || "/"} passHref>
            <a target="_blank" className="link">
              {t("View Website")}
            </a>
          </Link>
        </div>
      </StyledCard>
    </MainContainer>
  );
};

export { LinkCard };
