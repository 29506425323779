import { theme } from "@project/shared/src/theme";
import { styled } from "linaria/react";
import Link from "next/link";
import { useQuery } from "react-query";
import { GetNewsList } from "../../../service/news";
import moment from "moment";
import { Card } from "antd";
import SeeMoreIcon from "../../../assets/arrow.svg";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";

const Wrapper = styled.div`
  background-color: ${theme.gray4};
  & .see-all-wrapper {
    padding-bottom: 16px;
    padding-top: 6px;
  }
  & .see-all-div {
    & svg {
      margin-left: 8px;
    }
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: 0 auto;
    max-width: 1440px;
    & a:hover {
      & span {
        color: ${theme.buttonRed};
      }
      & svg > path {
        fill: ${theme.buttonRed};
      }
    }
  }
  & .see-all {
    ${theme.typography.bodySmall}
    color: ${theme.info};
  }
`;

const Carousel = styled.div`
  /* display: flex; */
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 48px 0px 18px;
  & > a:hover {
    & span {
      text-decoration: underline;
    }
  }
  ::-webkit-scrollbar {
    height: 6px;
    border-radius: 14px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb {
    background: #d1cece;
    border-radius: 14px;
    cursor: pointer;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #999393;
    outline: 1px solid slategrey;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    padding: 48px 0px 24px;
  }
  & > a:first-child div {
    padding-left: 0px;
  }
`;

const CarouselCard = styled.div<{ noBorder?: boolean }>`
  height: 100%;
  border-right: ${({ noBorder }) =>
    noBorder ? "none" : `1px solid ${theme.gray3}`};
  padding-left: 40px;
  flex: 0 0 auto;

  & span {
    display: block;
    min-width: 250px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 700;
    color: ${theme.gray0};
    padding-right: 16px;
  }
  & p {
    ${theme.typography.caption}
    color: ${theme.gray1};
    margin-bottom: 12px;
  }
`;

const Container = styled.div`
  max-width: 1440px;
  margin: auto;
  @media (max-width: 1550px) {
    padding: 0px 5%;
  }
  @media (max-width: 1440px) {
    padding: 0px 9.02% 0px 8.33%;
  }
  @media (max-width: 1245px) {
    padding: 0px 5%;
  }
  @media (max-width: 1150px) {
    padding: 0px 3%;
  }
  @media (max-width: 768px) {
    padding: 0px 16px;
  }
`;
const StyledCard = styled(Card)`
  flex: 0 0 auto;
  height: 100%;
  max-width: 450px;
  background-color: ${theme.gray4};

  & .ant-card-body {
    height: 100%;
    padding: 0px;
    background-color: ${theme.gray4};
  }
`;

const StyledTitle = styled.h2`
  margin-bottom: 0px;
  font-size: 24px;
  padding-top: 30px;
  line-height: 40px;
  font-weight: 700;
`;

interface Props {
  initialData?: any;
  fromCities?: boolean;
}

const slugIdMap: { [key: string]: string } = {
  osaka: "1",
  sakai: "2",
  hyogo_kobe: "3",
  kyoto: "4",
  shiga: "5",
  wakayama: "6",
  tokushima: "7",
  tottori: "8",
};

const TopCarousel = ({ initialData, fromCities }: Props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const id = slugIdMap[router.query.id as string];
  const { data } = useQuery<any, any>(
    ["get-news-list", id || null],
    GetNewsList,
    { refetchOnWindowFocus: false, initialData: initialData }
  );

  const handleSeeAllClicked = () => {
    if (id) {
      localStorage.setItem("news-city", id);
    }
  };
  return (
    <>
      {data?.data?.length > 0 && (
        <Wrapper>
          <Container>
            {fromCities && <StyledTitle>{t("News")}</StyledTitle>}
            <Carousel>
              {data?.data?.map((news, index) => (
                <Link href={news?.url || "/"} key={index}>
                  <a target="_blank">
                    <StyledCard key={index}>
                      <CarouselCard noBorder={data?.data?.length - 1 === index}>
                        <p>{moment(news.createdAt)?.format("YYYY-MM-DD")}</p>
                        <span>{news.title}</span>
                      </CarouselCard>
                    </StyledCard>
                  </a>
                </Link>
              ))}
            </Carousel>
            {data?.data?.length > 9 && (
              <div className="see-all-wrapper">
                <div className="see-all-div">
                  <Link href="/news">
                    <a onClick={handleSeeAllClicked}>
                      <span className="see-all">{t("See all news")}</span>
                      <SeeMoreIcon />
                    </a>
                  </Link>
                </div>
              </div>
            )}
          </Container>
        </Wrapper>
      )}
    </>
  );
};

export { TopCarousel };
