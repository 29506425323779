import { Card, Typography } from "antd";
import React from "react";
import { styled } from "linaria/react";
import { Button } from "@project/shared";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

const { Paragraph } = Typography;

const StyledCard = styled(Card)`
  margin-right: -56px;
  margin-top: 56px;
  height: fit-content;
  width: 100%;
  background: #f3f3f3;
  border-radius: 10px 10px 0px 10px;
  & .ant-card-body {
    padding: 32px 24px 93px 24px;
    min-width: 503px;
    min-height: 477px;
  }
  & strong {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  }
  max-width: 503px;
  @media (max-width: 975px) {
    margin-top: 30px;
    & .ant-card-body {
      padding: 32px 24px;

      min-height: 378px;
    }
  }

  @media (max-width: 925px) {
    max-width: 450px;
    & .ant-card-body {
      min-width: 450px;
    }
  }

  @media (max-width: 768px) {
    max-width: unset;
    background: unset;
    margin-top: 0px;
    border: unset;
    & .ant-card-body {
      min-width: unset;
      padding: 32px 10px 64px 10px;
    }
    & strong {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      letter-spacing: 0.0015em;
    }
  }
`;
const StyledParagraph = styled(Paragraph)`
  margin-top: 24px;
  margin-bottom: 24px !important;
  font-style: normal;
  font-weight: normal;
  min-height: 90px;
  font-size: 18px;
  line-height: 32px;
  color: #4b4e4b;

  @media (max-width: 768px) {
    margin-top: 16px;
    margin-bottom: 36px !important;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0015em;
    color: #555555;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const Image = styled.div`
  height: 645px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  flex: 1;
  border-radius: 10px;

  @media (max-width: 1440px) {
    background-size: contain;
    background-position: center center;
  }

  @media (min-width: 1441px) {
    background-size: cover;
  }
  @media (max-width: 975px) {
    height: 500px;
    background-size: cover;
    background-position: center;
  }
  @media (max-width: 768px) {
    background-size: 100%;
    height: 297px;
    flex: unset;
    border-radius: unset;
  }
`;
interface NoticeCardProps {
  title?: string;
  description?: string;
  image?: string;
  slug: string;
}

export const NoticeCard = ({
  title,
  description,
  image,
  slug,
}: NoticeCardProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  return (
    <Wrapper>
      <StyledCard>
        <strong>{title}</strong>
        <StyledParagraph ellipsis={{ rows: 4, expandable: false }}>
          {description}
        </StyledParagraph>
        <Button
          minwidth={133}
          minheight={48}
          padding="12px 24px"
          fontWeight="600"
          fontSize="16px"
          type="primary"
          onClick={() => router.push(`/articles/${slug}`)}
        >
          {t("Read more")}
        </Button>
      </StyledCard>
      <Image
        style={{
          backgroundImage: image
            ? `url(${image})`
            : `url("/featured_small.svg")`,
        }}
      />
    </Wrapper>
  );
};
