import { theme } from "@project/shared/src/theme";
import { styled } from "linaria/react";
import { useTranslation } from "react-i18next";
import Link from "next/link";
import { Divider, Select } from "antd";
import DownIcon from "../../../assets/downIcon.svg";
import TwitterIcon from "../../../assets/twitter.svg";
import FacebookIcon from "../../../assets/facebook.svg";
import { Button, i18n } from "@project/shared";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";

const { Option } = Select;

const Container = styled.footer`
  border-top: 1px solid ${theme.gray3};
  & .language-title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */

    /* Grey/0 */

    color: #191a19;
  }
`;

const Wrapper = styled.div`
  max-width: 1440px;
  padding-top: 64px;
  padding-bottom: 23px;
  margin: auto;

  & img:hover,
  svg:hover {
    opacity: 0.8;
  }

  & .union-logo {
    margin: 16px 0px 13px;
    object-fit: contain;
  }

  & .ant-divider {
    margin: 26px 0px 23px;
    border-color: ${theme.gray};
  }

  & > span {
    ${theme.typography.captionSmall}
    color: ${theme.title};
  }
  @media (max-width: 1550px) {
    padding: 64px 5% 23px 5%;
  }
  @media (max-width: 1440px) {
    padding: 64px 8.33% 23px 8.33%;
  }
  @media (max-width: 1245px) {
    padding: 64px 5% 23px 5%;
  }
  @media (max-width: 1150px) {
    padding: 64px 3% 23px 3%;
  }
  @media (max-width: 975px) {
    & > span {
      white-space: break-spaces;
    }
  }
  @media (max-width: 768px) {
    padding: 32px 0px 16px 0px;
    & .union-logo {
      margin: 32px 0px 24px;
    }
    & > span {
      margin-left: 16px;
      margin-right: 16px;
      display: block;
    }
  }
`;

const Row = styled.div`
  justify-content: space-between;
  display: flex;

  & .logo-wrapper {
    & span {
      margin-left: 20px;
    }
  }

  @media (max-width: 1075px) and (min-width: 976px) {
    & > div:not(:last-child) {
      margin-right: 16px;
    }
  }
  @media (max-width: 975px) {
    flex-wrap: wrap;
    & .col-1 {
      max-width: unset;
      width: 100%;
      order: 0;
      margin-bottom: 32px;
    }

    & .col-2 {
      width: 100%;
      order: 3;
      margin-top: 35px;
    }

    & .col-3,
    .col-4 {
      width: 40%;
      & button {
        max-width: unset;
      }
      & .ant-select {
        width: 100% !important;
      }
    }
    & .col-3 {
      order: 2;
    }
    & .col-4 {
      order: 1;
    }

    & .follow {
      display: flex;
      & h4 {
        min-width: unset;
        margin-right: 28px;
        white-space: nowrap;
      }
      & .follow-wrapper {
        display: flex;
      }
    }
  }

  @media (max-width: 768px) {
    margin: 0px 16px;

    & .col-3,
    .col-4 {
      width: 45%;
    }
  }
  @media (max-width: 390px) {
    & .col-3,
    .col-4 {
      width: 48%;
    }
  }
  @media (max-width: 365px) {
    & .col-3,
    .col-4 {
      width: 100%;
    }
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & a {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 21px;
    color: ${theme.title};
    min-width: 143px;
    &:hover {
      color: ${theme.buttonRed};
    }
  }
  & .active {
    color: ${theme.buttonRed};
    font-weight: 700;
  }
  & a:last-child {
    margin-bottom: 0px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 466px;
  & .info {
    font-size: 14px;
    line-height: 22px;
    color: ${theme.title};
    margin-bottom: 0px;
    white-space: break-spaces;
  }
  & .links {
    background-color: red;
  }

  & .language-title {
    font-size: 16px;
    line-height: 28px;
    min-width: 130px;
  }

  & .ant-select {
    width: 113px !important;
  }
  & .ant-select-selector {
    & span {
      font-size: 16px;
    }

    border-color: ${theme.gray2} !important;
    border-radius: 10px !important;
    height: 46px !important;

    &:hover {
      border-color: ${theme.buttonRed} !important;
      & .ant-select-selection-item {
        color: ${theme.buttonRed};
      }
    }
  }
  & .ant-select-selection-item {
    font-weight: 600;
    margin: auto;
    font-size: 16px;
    line-height: 21px;
  }

  & .ant-select-arrow {
    right: 16px;
  }
  & button {
    margin-bottom: 33px;
    min-width: 150px;
    max-width: 150px;
    min-height: 45px;
    padding: 0px;
  }
  & .m-13 {
    margin-bottom: 13px;
  }
  & .m-12 {
    margin-bottom: 12px;
  }
  & .m-11 {
    margin-bottom: 11px;
  }
  & .follow-wrapper {
    & > :first-child {
      margin-right: 23px;
    }
  }
`;

const Footer = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const [language, setLanguage] = useState("ja");

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  const handleLanguageChange = (value) => {
    let path = router.pathname;
    if (
      router?.pathname?.startsWith("/city") ||
      router?.pathname?.startsWith("/articles/[id]")
    ) {
      path = `${router.pathname?.startsWith("/city") ? "/city" : "/articles"}/${
        router.query.id
      }`;
    }
    if (value == "en") {
      window.location?.assign(`https://en.kansai-startup-ecosystem.com${path}`);
    }

    if (value == "ja") {
      window.location?.assign(`https://kansai-startup-ecosystem.com${path}`);
    }
  };

  const getDefaultValue = () => {
    if (typeof window !== "undefined") {
      if (window.location.href.startsWith("https://kansai-startup")) {
        return "ja";
      } else if (window.location.href.startsWith("https://en.kansai-startup")) {
        return "en";
      } else if (i18n.language?.startsWith("ja")) {
        return "ja";
      }
    }
    return "en";
  };
  return (
    <Container>
      <Wrapper>
        <Row>
          <Column className="col-1">
            <div className="logo-wrapper">
              <Link href="/">
                <a>
                  <img
                    src="/logo.svg"
                    alt="Logo Footer"
                    width={152}
                    height={44}
                  />
                </a>
              </Link>
            </div>
            <a href="https://www.kouiki-kansai.jp/" target="_blank">
              <picture>
                <source
                  type="image/webp"
                  srcSet={
                    language.startsWith("ja")
                      ? "/union_logo_ja.webp"
                      : "/union_logo_en.webp"
                  }
                />
                <img
                  src={
                    language.startsWith("ja")
                      ? "/union_logo_ja.png"
                      : "/union_logo_en.png"
                  }
                  alt={t(
                    "Union of Kansai Governments Wide Area Industry Promotion Bureau"
                  )}
                  width="170"
                  height={37}
                  className="union-logo"
                />
              </picture>
            </a>
            <p className="info">
              {t(
                "Union of Kansai Governments Wide Area Industry Promotion Bureau"
              )}
            </p>
          </Column>
          <LinksWrapper className="col-2">
            <Link href="/about">
              <a className={router.pathname === "/about" && "active"}>
                {t("About")}
              </a>
            </Link>
            <Link href="/articles">
              <a
                className={
                  router.pathname?.startsWith("/articles") ? "active" : null
                }
              >
                {t("Articles")}
              </a>
            </Link>
            <Link href="/events">
              <a
                className={
                  router.pathname?.startsWith("/events") ? "active" : null
                }
              >
                {t("Events")}
              </a>
            </Link>
            <Link href="/startup-support">
              <a
                className={
                  router.pathname === "/startup-support" ? "active" : null
                }
              >
                {t("Startup Supports")}
              </a>
            </Link>

            <Link href="/cities">
              <a
                className={
                  (router.pathname === "/cities" ||
                    router.pathname?.startsWith("/city")) &&
                  "active"
                }
              >
                {t("Cities")}
              </a>
            </Link>

            <Link href="/keihanshin-global-hub-city">
              <a
                className={
                  router.pathname === "/keihanshin-global-hub-city"
                    ? "active"
                    : null
                }
              >
                {t("Keihanshin Global Hub City")}
              </a>
            </Link>
            <Link href="/policy">
              <a className={router.pathname === "/policy" ? "active" : null}>
                {t("Site policy")}
              </a>
            </Link>
          </LinksWrapper>
          <Column className="col-3">
            <label htmlFor="lang" className="language-title m-13">
              {t("Choose language")}
            </label>
            <Select
              id="lang"
              aria-labelledby="option"
              defaultValue={getDefaultValue}
              suffixIcon={<DownIcon />}
              onChange={handleLanguageChange}
            >
              <Option value="en">English</Option>
              <Option value="ja">日本語</Option>
            </Select>
          </Column>
          <Column className="col-4">
            <strong className="language-title m-12">
              {t("Have any queries?")}
            </strong>
            <Button type="primary" onClick={() => router.push("/contact-us")}>
              {t("Contact us")}
            </Button>
            <div className="follow">
              <strong className="language-title m-11">
                {t("Follow us: ")}
              </strong>
              <div className="follow-wrapper">
                {/* <Link
                  href="https://www.facebook.com/deeptechvalleykansai"
                  passHref
                > */}
                <a
                  href="https://www.facebook.com/deeptechvalleykansai"
                  target="_blank"
                  aria-label="Facebook"
                >
                  <FacebookIcon />
                </a>
                {/* </Link> */}

                {/* <Link href="https://twitter.com/deeptechvalley"> */}
                <a
                  href="https://twitter.com/deeptechvalley"
                  target="_blank"
                  aria-label="Twitter"
                >
                  <TwitterIcon />
                </a>
                {/* </Link> */}
              </div>
            </div>
          </Column>
        </Row>
        <Divider />
        <span>
          {
            "Copyright © 2021 Union of Kansai Governments \nAll Rights Reserved."
          }
        </span>
      </Wrapper>
    </Container>
  );
};

export { Footer };
