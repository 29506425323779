import { Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const SeeMoreLess = ({ index, item }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Typography.Paragraph
      key={index + isExpanded.toString()}
      ellipsis={{
        rows: isExpanded ? undefined : 4,
        expandable: true,
        symbol: (
          <span className="seeMore" onClick={() => setIsExpanded(!isExpanded)}>
            {t("See all")}
          </span>
        ),
      }}
    >
      {item}
      {isExpanded && (
        <span
          className="seeLess"
          onClick={(e) => {
            e.stopPropagation();
            setIsExpanded(!isExpanded);
          }}
        >
          {t("See less")}
        </span>
      )}
    </Typography.Paragraph>
  );
};
