import { theme } from "@project/shared/src/theme";
import { Progress } from "antd";
import { styled } from "linaria/react";
import { useEffect, useState } from "react";

const Card = styled.div<{ selected: boolean; length: number }>`
  padding: 16px;
  width: ${({ length }) => `calc(100% / ${length} - 8px)`};
  cursor: pointer;
  border-radius: 8px;
  & .ant-progress-inner {
    background-color: rgb(255 255 255 / 45%);
  }
  & .ant-progress {
    max-width: 120px;
    display: flex;
    height: 5px;
  }
  & .ant-progress-bg {
    height: 5px !important;
    background-color: ${theme.buttonRed};
  }

  & .ant-progress-outer {
    display: flex;
  }

  background: transparent;
  mix-blend-mode: normal;

  &:hover {
    background: #c6c6c626;
  }

  @media (max-width: 400px) {
    width: ${({ length }) => `calc(100% / ${length} - 8px)`};
    display: none;
  }

  @media (max-width: 768px) {
    padding: 0px;
  }
`;

interface Props {
  selected: boolean;
  onClick: any;
  id: string;
  length: number;
}
const ProgressCard = ({ selected, onClick, id, length }: Props) => {
  const [countdown, setCountdown] = useState(0);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (countdown < 1) {
        setTimer((t) => t + 1);
      }
    }, 1);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setCountdown(timer / 12);
  }, [timer]);

  useEffect(() => {
    setCountdown(0);
    setTimer(0);
  }, [selected]);

  return (
    <Card selected={selected} onClick={onClick} id={id} length={length}>
      <Progress percent={selected ? countdown : 0} showInfo={false} />
    </Card>
  );
};

export { ProgressCard };
