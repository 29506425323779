import { Button } from "@project/shared";
import { theme } from "@project/shared/src/theme";
import { styled } from "linaria/react";
import { useTranslation } from "react-i18next";

const Card = styled.div`
  min-width: 384px;
  max-width: 384px;

  & h6,
  h4 {
    margin-bottom: 8px;
    white-space: break-spaces;
  }

  & h4 {
    font-size: 14px;
    line-height: 20px;
    color: ${theme.grey2};
  }

  & h6 {
    ${theme.typography.subtitle2}
    color: ${theme.gray0};
  }

  & p {
    text-align: justify;
    ${theme.typography.bodySmall}
    color: ${theme.gray1};
    margin-bottom: 16px;
    white-space: break-spaces;
  }

  & button {
    padding: 0px;
    min-width: 133px;
    min-height: 48px;
    border-color: ${theme.buttonRed};
    & span {
      color: ${theme.buttonRed};
    }
    &:hover,
    &:focus {
      border-color: #cb3341;
      & span {
        color: #cb3341;
      }
    }
  }

  @media (max-width: 1200px) {
    min-width: 300px;
  }

  @media (max-width: 975px) {
    min-width: 270px;
  }

  @media (max-width: 625px) {
    min-width: unset;
    max-width: unset;
  }
`;

interface Props {
  subtitle: string;
  title: string;
  description: string;
  url: string;
}

const SupportCardDetails = ({ subtitle, title, description, url }: Props) => {
  const { t } = useTranslation();

  return (
    <Card>
      <h4>{subtitle}</h4>
      <h6>{title}</h6>
      <p>{description}</p>
      {url && (
        <Button type="link" onClick={() => window.open(url, "_blank")}>
          {t("Read more")}
        </Button>
      )}
    </Card>
  );
};

export { SupportCardDetails };
